import React, { useReducer } from "react";
import FromToPage from "../../components/FromToPage";
import CardItem from "./components/cardItem/index";
import BankItems from "./components/bankItem/index";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCreditPageData } from "../../store/MainSlice";
import langData from "../../lang/data";
import LoadContent from "../../components/LoadContent";

export default function Credit() {
  const dispatch = useDispatch();
  const { currentLanguage, creditPageData } = useSelector(({ main }) => main);
  useEffect(() => {
    dispatch(getCreditPageData());
  }, []);

  const bankItemsList = [
    {
      id: 1,
      img: "https://vlv.am/public//uploads/images/pages/63e1fc1c6a434.png",
      phoneNumber: "(010)510510",
      bankName: "inecobank.am",
      href: "https://www.inecobank.am/",
      creditPage: "https://www.inecobank.am/hy/Individual/point-of-sales",
    },
    {
      id: 2,
      img: "https://vlv.am/public//uploads/images/pages/63e1f07a8e9eb.png",
      phoneNumber: "+(374 8000) 87-87",
      bankName: "vtb.am",
      creditPage:
        "https://www.vtb.am/am/credits/installments/installments-loan/",
      href: "https://www.vtb.am/",
    },
    {
      id: 3,
      img: "https://vlv.am/public//uploads/images/pages/64396ae6e4727.png",
      phoneNumber: "010/012 561111",
      bankName: "ameriabank.am",
      creditPage:
        "https://ameriabank.am/personal/loans/consumer-loans/consumer-finance",
      href: "https://ameriabank.am/",
    },
    {
      id: 4,
      img: "https://vlv.am/public//uploads/images/pages/63e1fcabf1d09.png",
      phoneNumber: "(010) 318888",
      bankName: "acba.am",
      creditPage: "https://www.acba.am/hy/individuals/loans/installment-loan",
      href: "https://www.acba.am/",
    },
    {
      id: 5,
      img: "https://vlv.am/public//uploads/images/pages/64396a3c1b616.png",
      phoneNumber: "(010)592259,595555",
      bankName: "unibank.am",
      creditPage: "https://www.unibank.am/hy/service/consumer.php",
      href: "https://www.unibank.am/",
    },
    {
      id: 6,
      img: "https://vlv.am/public//uploads/images/pages/63e1f986b8520.png",
      phoneNumber: "(010)605555",
      bankName: "evoca.am",
      creditPage:
        "https://www.evoca.am/hy/loans/online-and-point-of-sale-loans/",
      href: "https://www.evoca.am/",
    },
    {
      id: 7,
      img: "https://vlv.am/public//uploads/images/pages/63e1fd369899c.png",
      phoneNumber: "(012)777772",
      bankName: "artsakhbank.am",
      creditPage: "https://www.artsakhbank.am/loans/consumer-loans/installment",
      href: "https://www.artsakhbank.am/",
    },
    {
      id: 8,
      img: "https://vlv.am/public//uploads/images/pages/63e1fa69ee472.png",
      phoneNumber: "(012)241024",
      bankName: "velox.am",
      creditPage:
        "https://www.velox.am/%D5%BD%D5%A1%D5%AF%D5%A1%D5%A3%D5%B6%D5%A5%D6%80-%D6%87-%D5%BA%D5%A1%D5%B5%D5%B4%D5%A1%D5%B6%D5%B6%D5%A5%D6%80",
      href: "https://www.velox.am/",
    },
    {
      id: 9,
      img: "https://vlv.am/public//uploads/images/pages/641029fa4f72b.png",
      phoneNumber: "86 86 ,(010)510910",
      bankName: "aeb.am",
      creditPage: "https://www.aeb.am/hy/aparik-aeb/",
      href: "https://www.aeb.am/",
    },
    {
      id: 9,
      img: "https://vlv.am/public//uploads/images/pages/agrocredit.svg",
      phoneNumber: "+(374)60-440-552",
      bankName: "agrocredit.am",
      creditPage: "https://www.agrocredit.am/carayutyunner/service-listing/varker-anhatnerin",
      href: "https://www.agrocredit.am",
    },
  ];
  return (
    <div className="wrapper">
      <FromToPage
        pages={[
          {
            label: langData.header.menu.Credit[currentLanguage],
            value: "/credit",
            active: true,
          },
        ]}
      />
      <div className={styles.creditBanner}>
        {!creditPageData ? (
          <LoadContent containerClassName={styles.LoadContent} />
        ) : (
          <img
            className={styles.creditBannerImg}
            src={
              process.env.REACT_APP_STORAGE_URL +
              creditPageData?.mainPhoto["image_" + currentLanguage]
            }
            alt="img"
          />
        )}
      </div>
      <div className={styles.description}>
        {!creditPageData ? (
          <LoadContent containerClassName={styles.LoadContentDesc} />
        ) : (
          <element
            dangerouslySetInnerHTML={{
              __html: creditPageData?.credits_info["text_" + currentLanguage],
            }}
          ></element>
        )}
      </div>
      <div className={styles.creditInfoBlock}>
        {!creditPageData ? (
          <LoadContent containerClassName={styles.LoadContentcreditInfoBlock} />
        ) : (
          creditPageData?.credits_info["banners_" + currentLanguage].map(
            (item, index) => <CardItem key={index + 1} item={item} />
          )
        )}
      </div>
      <div className={styles.chooseBanksInfo}>
        <span className={styles.chooseBanksInfoTitle}>
          {langData?.credit?.MoreInformation[currentLanguage]}
        </span>
        <span className={styles.chooseBanksInfoSecondTitle}>
          {langData?.credit?.ControledByCB[currentLanguage]}
        </span>
        <span>
          Ապառիկի ֆինանսավորման պայմաններին ծանոթանալու համար սեղմեք
          տարբերանշանի վրա։
        </span>
        <div className={styles.banksAbout}>
          {bankItemsList.map((item) => (
            <BankItems item={item} />
          ))}
        </div>
      </div>
    </div>
  );
}
