import React, { forwardRef, useEffect, useState } from "react";
import style from "./style.module.css";
import lang from "../../../../../../lang/lang";
import { useSelector } from "react-redux";
import Map from "../../../../../../components/Map";
import langData from "lang/data";

const CartForm = forwardRef(
  (
    {
      firstNameRef,
      lastNameRef,
      emailRef,
      phoneRef,
      mapRef,
      firstName,
      setFirstName,
      lastName,
      setLastName,
      email,
      setEmail,
      phone,
      setPhone,
      phone2,
      setPhone2,
      firstNameError,
      lastNameError,
      emailError,
      phoneError,
      addressName,
      setAddressName,
      addressName2,
      setAddressName2,
      addressNameError,
      addressType,
      setAddressType,
    },
    ref
  ) => {
    const currentLanguage = useSelector(({ main }) => main.currentLanguage);
    const defaultCenter = { lat: 40.150011, lng: 44.495715 };
    const [mapCenter, setMapCenter] = useState(defaultCenter);

    useEffect(() => {
      setAddressName("");
      setAddressName2("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressType]);

    return (
      <form
        className={style.add_personal_form}
        ref={ref}>
        <h1>{lang[currentLanguage].personal_information}</h1>
        <div className={style.add_personal_inputs}>
          <input
            ref={firstNameRef}
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className={` ${firstNameError && style.inputError}`}
            placeholder={lang[currentLanguage].first_name + " *"}
          />

          <input
            ref={lastNameRef}
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className={` ${lastNameError && style.inputError}`}
            placeholder={lang[currentLanguage].last_name}
          />
        </div>
        <div className={style.add_personal_inputs}>
          <input
            ref={emailRef}
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${emailError && style.inputError}`}
            placeholder={lang[currentLanguage].email + " *"}
          />
          <div className={style.add_personal_inputs_phone}>
            <input
              ref={phoneRef}
              type="text"
              value={
                phone?.[0]
                  ? (phone?.[0] === "0" ? "" : "+") + phone?.replace(/\D/g, "")
                  : phone
              }
              onChange={(e) => setPhone(e.target.value.toString())}
              className={`${phoneError && style.inputError}`}
              placeholder={langData.loginModal.ModaPhone[currentLanguage] + "*"}
            />
            <input
              type="text"
              value={phone2}
              onChange={(e) => setPhone2(e.target.value.toString())}
              placeholder={lang[currentLanguage].phone}
            />
          </div>
        </div>
        <span className={style.second_title}>
          {lang[currentLanguage].Delivery_Type}
        </span>
        <div className={style.delivery_list}>
          <div
            onClick={() => setAddressType(0)}
            className={
              addressType !== 0
                ? style.choose_type_delivery
                : style.choose_type_delivery_active
            }>
            <span>{lang[currentLanguage]["Delivery in Yerevan"]}</span>
          </div>

          <div
            onClick={() => setAddressType(1)}
            className={
              addressType !== 1
                ? style.choose_type_delivery
                : style.choose_type_delivery_active
            }>
            <span>{lang[currentLanguage]["Delivery Regions"]}</span>
          </div>
        </div>
        <span style={{ fontSize: "14px" }}>
          {lang[currentLanguage]["Fill in the data"]}
        </span>

        <Map
          input={
            <div className={style.add_personal_inputs} style={{marginBottom: 20}}>
              <input
                ref={mapRef}
                type="text"
                name="name"
                placeholder={
                  addressType !== 0
                    ? "Մարզ, համայնք, հասցե ..."
                    : lang[currentLanguage][
                        "City, region, community, address..."
                      ]
                }
                value={addressName}
                onChange={(e) => setAddressName(e.target.value)}
                className={`${addressNameError && style.inputError}`}
                addressName={addressName}
              />
            </div>
          }
          disableMap={addressType !== 0}
          setAddressName={setAddressName}
          // addresses={addresses}
          mapCenter={mapCenter}
          // handleMarkerClick={handleMarkerClick}
          setMapCenter={setMapCenter}
        />

        <div className={style.add_personal_inputs}>
          <input
            type="text"
            className="general_inps name"
            value={addressName2}
            onChange={(e) => setAddressName2(e.target.value)}
            placeholder={lang[currentLanguage].flat_room}
          />
        </div>
      </form>
    );
  }
);

export default CartForm;
