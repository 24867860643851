import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  grouped_products: [],
  imagesPath: "",
  loading_grouped_products: false,
  error: null,
};

export const sendGroupedProductsRequest = createAsyncThunk(
  "send_grouped_products/send_grouped_products_request",
  async (
    {
      grouped_product_id,
      name,
      surname,
      email,
      phone,
      gateway_id,
      payment_id,
      address_inp,
      credit,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/grouped-product/order/store`,
        {
          login: process.env.REACT_APP_LOGIN,
          password: process.env.REACT_APP_PASSWORD,
          token: process.env.REACT_APP_TOKEN,
          grouped_product_id,
          name,
          surname,
          email,
          phone,
          gateway_id,
          payment_id,
          address_inp,
          credit,
        }
      );

      return response.data;
    } catch (error) {   
      if (error.response && error.response.data && error.response.data.message) {
        const messages = error.response.data.message;
        // Отправляем все сообщения об ошибках через rejectWithValue
        return rejectWithValue(messages);
      }

      // Если нет конкретных сообщений об ошибке, отправляем общий message
      return rejectWithValue(error.message);
    }
  }
);

const sendGroupedProductsSlice = createSlice({
  name: "send_grouped_products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(sendGroupedProductsRequest.pending, (state) => {
        state.loading_grouped_products = true;
        state.error = null;
      })

      .addCase(sendGroupedProductsRequest.fulfilled, (state, { payload }) => {
        const { data, imagesPath } = payload;
        state.grouped_products = data;
        state.imagesPath = imagesPath;
        state.loading_grouped_products = false;
      })

      .addCase(sendGroupedProductsRequest.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading_grouped_products = false;
      });
  },
});

export default sendGroupedProductsSlice.reducer;
