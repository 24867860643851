import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import UseCalcPrice from "../../../../../../helpers/UseCalcPrice";
import { useDispatch, useSelector } from "react-redux";
import PaymentType from "./components/PaymentType";
import UseOutSideClick from "../../../../../../helpers/UseOutSideClick";
import langData from "../../../../../../lang/data";
import {
  addCardStore,
  postCreditModal,
} from "../../../../../../store/MainSlice";
import data from "./data";
import axios from "axios";
import CircleLoader from "../../../../../../components/CircleLoader/index";
import isValidEmail from "../../../../../../helpers/checkEmail";
import isValidPhone from "../../../../../../helpers/checkPhone";
import axiosInstance from "../../../../../../store/request";
import lang from "../../../../../../lang/lang";
import Agreement from "../../../../../CartContent/components/Cart/components/Agreement";
import ThanksModal from "../../../../../../components/CreditMobal/components/ThanksModal";

export default function TreeInOneModal({ modalType, closeModal }) {
  const [activePaymentType, setActivePaymentType] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [flat, setFlat] = useState("");
  const [comment, setComment] = useState("");

  const [agreementInfo, setAgreementInfo] = useState(false);
  const [thanksModal, setThanksModal] = useState(false);
  const [submitFormTag, setSubmitFormTag] = useState(null);
  const [firstNameError, setFirstNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [pending, setPending] = useState(false);

  const { currentCurrency, currentLanguage } = useSelector(({ main }) => main);
  const { treeInOne } = useSelector(({ home }) => home);

  const mainRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    const formSubmit = document.querySelector("#formSubmit form");
    if (formSubmit?.submit) {
      formSubmit.submit();
      setSubmitFormTag(null);
    }
  }, [submitFormTag]);
  useEffect(() => {
    if (modalType) {
      treeInOne?.products?.forEach((product) => {
        dispatch(
          addCardStore({
            price:
              product?.promo_price ||
              +(product?.online_price && product?.online_selling_price) ||
              product?.skus[0]?.selling_price,
            qty: 1,
            product_id: product?.skus?.[0]?.id,
            seller_id: 1,
            shipping_method_id: 0,
            type: "product",
            is_buy_now: "yes",
          })
        );
      });
      axios.post(process.env.REACT_APP_SERVER_URL + "getThreeInOnePrice", {
        _token: "wSNUTKPsZljk5Lp1JOo5Mj4VlNHZZXZ1JFJXJMF6",
        id: 1,
      });

      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  UseOutSideClick(mainRef, closeModal);

  if (!modalType) return null;

  const reqestFormTag = (formTag) => {
    setPending(false);
    setSubmitFormTag(formTag?.data);
    return null;
  };

  return (
    <div className={styles.background}>
      <div ref={mainRef}>
        <div
          style={!pending ? { display: "none" } : null}
          className={styles.loader}
        >
          <CircleLoader />
        </div>
        <div
          className={styles.main}
          style={
            pending || !thanksModal
              ? { display: "none" }
              : { padding: 0, margin: 0 }
          }
        >
          <ThanksModal
            data={{
              ...agreementInfo,
              name: firstName,
              surname: lastName,
              email: email,
              phone: phone,
              flat: flat,
              address_inp: address,
              note: comment,
            }}
            qty={3}
            product={treeInOne?.products}
            totalPrice={treeInOne?.threeInOne?.price}
            show_payment_type={false}
            close={() => {
              setAgreementInfo(null);
              setThanksModal(null);
              closeModal();
            }}
          />
        </div>
        <div
          className={styles.main}
          style={
            pending || !agreementInfo
              ? { display: "none" }
              : { padding: 0, margin: 0 }
          }
        >
          <Agreement
            setPending={setPending}
            fullName={firstName + " " + lastName}
            agreementInfo={agreementInfo}
            back={() => setAgreementInfo(null)}
            close={() => {
              setAgreementInfo(null);
              setThanksModal(null);
              closeModal();
            }}
            setThanksModal={() => setThanksModal(true)}
            withScroll={false}
          />
        </div>
        <div
          className={styles.main}
          style={pending || agreementInfo ? { display: "none" } : null}
        >
          <div className={styles.header}>
            <span className={styles.title}>
              {data?.[modalType].title[currentLanguage]}
            </span>
            <span className={styles.closeBtn} onClick={closeModal}>
              <svg
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1 1L16 16M16 1L1 16" stroke="#282828"></path>
              </svg>
            </span>
          </div>
          <div className={styles.twoInputs}>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              className={`${styles.input} ${
                firstNameError && styles.inputError
              }`}
              placeholder={langData.loginModal.FirstName[currentLanguage] + "*"}
            />
            <input
              type="text"
              value={lastName}
              className={styles.input}
              onChange={(e) => setLastName(e.target.value)}
              placeholder={langData.loginModal.LastName[currentLanguage]}
            />
          </div>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={`${styles.input} ${emailError && styles.inputError}`}
            placeholder={langData.loginModal.email[currentLanguage] + "*"}
          />
          <input
            type="text"
            value={phone?.[0] ? (phone?.[0] === "0" ? "" : "+") + phone?.replace(/\D/g, "") : phone} 
            onChange={(e) => setPhone(e.target.value)}
            className={`${styles.input} ${phoneError && styles.inputError}`}
            placeholder={langData.loginModal.ModaPhone[currentLanguage] + "*"}
          />
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            className={`${styles.input} ${addressError && styles.inputError}`}
            placeholder={data.lang.address[currentLanguage]}
          />
          <input
            type="text"
            value={flat}
            onChange={(e) => setFlat(e.target.value)}
            className={styles.input}
            placeholder={data.lang.flat[currentLanguage]}
          />
          <div className={styles.priceBlock}>
            <span className={styles.priceText}>
              {data.lang.delivary[currentLanguage]}
            </span>
            <span className={styles.price}></span>
          </div>
          <div className={styles.priceBlock}>
            <span className={styles.priceText}>
              {data.lang.total[currentLanguage]}
            </span>
            <span className={styles.price}>
              {UseCalcPrice(treeInOne?.threeInOne?.price, currentCurrency)}
            </span>
          </div>
          <textarea
            name="coment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className={styles.input}
            style={{ minHeight: "100px", resize: "none" }}
            cols="30"
            rows="5"
            placeholder={data.lang.textarea[currentLanguage]}
            contentEditable={false}
          ></textarea>
          <span className={styles.paymentTitle}>
            {data?.[modalType].paymentTitle[currentLanguage]}
          </span>
          <div className={styles.paymentsBlock}>
            {data?.[modalType].paymentTypes?.map((item, index) => (
              <PaymentType
                key={index}
                imgs={item?.imgs || item}
                active={activePaymentType === index}
                setActive={() => setActivePaymentType(index)}
                title={item?.title?.[currentLanguage]}
              />
            ))}
          </div>
          {errorMessage && (
            <span className={styles.errorMessage}>{errorMessage}</span>
          )}
          <div
            className={styles.buyBtn}
            onClick={() => {
              setFirstNameError(!firstName);
              setEmailError(!email || !isValidEmail(email));
              setAddressError(!address);
              setPhoneError(!phone || !isValidPhone(phone));

              if (
                firstName &&
                email &&
                isValidEmail(email) &&
                address &&
                phone &&
                isValidPhone(phone)
              ) {
                setPending(true);
                const sendData = {
                  name: firstName,
                  surname: lastName,
                  email: email,
                  phone: phone,
                  flat: flat,
                  address_inp: address,
                  note: comment,
                  discount: 1,
                  // checkout_type: "buy_it_now",
                  is_buy_now: "yes",
                  number_of_package: 3,
                  number_of_item: 3,
                  discount_total:
                    +treeInOne?.products?.[0]?.skus[0]?.selling_price +
                    +treeInOne?.products?.[1]?.skus[0]?.selling_price +
                    +treeInOne?.products?.[2]?.skus[0]?.selling_price -
                    +treeInOne?.threeInOne?.price,
                  grand_total: treeInOne?.threeInOne.price,
                  sub_total: treeInOne?.threeInOne.price,
                };
                if (modalType === "credit") {
                  sendData.credit = ["Vtb","ineco", "acba",]?.[
                    activePaymentType
                  ];
                  sendData.gateway_id = 18;
                  sendData.payment_id = 18;
                } else {
                  sendData.gateway_id =
                    data.buyNow.paymentTypes[activePaymentType].id;
                  sendData.payment_id =
                    data.buyNow.paymentTypes[activePaymentType].id;
                }
                dispatch(
                  postCreditModal(sendData, (e) => {
                    if (e?.data?.order?.id) {
                      if (
                        modalType === "credit" &&
                        data.credit.paymentTypes?.[activePaymentType] ===
                          "img/vtb.png"
                      ) {
                        window.location.href =
                          "https://www.vtb.am/am/register/";
                      } else if (
                        modalType === "buyNow" &&
                        data.buyNow?.paymentTypes?.[activePaymentType].id === 1
                      ) {
                        setPending(false);
                        setAgreementInfo({
                          order_id: e.data.order.id,
                          order_number: e.data.order.order_number,
                        });
                        setThanksModal(true);
                      } else if (
                        modalType === "buyNow" &&
                        data.buyNow?.paymentTypes?.[activePaymentType].id === 16
                      ) {
                        axiosInstance
                          .get(process.env.REACT_APP_SERVER_URL + "tellcell", {
                            params: {
                              id: e.data.order.id,
                              total: +treeInOne?.threeInOne.price,
                            },
                          })
                          .then(reqestFormTag)

                          .catch(() => setPending(false));
                      } else if (
                        modalType === "buyNow" &&
                        data.buyNow?.paymentTypes?.[activePaymentType].id === 15
                      ) {
                        axiosInstance
                          .get(process.env.REACT_APP_SERVER_URL + "idram", {
                            params: {
                              id: e.data.order.id,
                              total: +treeInOne?.threeInOne.price,
                            },
                          })
                          .then(reqestFormTag)

                          .catch(() => setPending(false));
                      } else if (
                        modalType === "buyNow" &&
                        data.buyNow?.paymentTypes?.[activePaymentType].id === 17
                      ) {
                        axiosInstance
                          .get(
                            process.env.REACT_APP_SERVER_URL + "arca/paymant/5",
                            {
                              params: {
                                id: e.data.order.order_number,
                                total: +treeInOne?.threeInOne.price,
                              },
                            }
                          )
                          .then((e) => {
                            if (e.data.fail) {
                              setPending(false);
                              setErrorMessage(
                                lang[currentLanguage].error_message
                              );
                            } else {
                              setPending(false);
                              setSubmitFormTag(e.data?.form);
                            }
                          });
                      } else if (
                        modalType === "buyNow" &&
                        data.buyNow?.paymentTypes?.[activePaymentType].id === 19
                      ) {
                        axiosInstance
                          .get(
                            process.env.REACT_APP_SERVER_URL + "ineco/index10",
                            {
                              params: {
                                id: e.data.order.order_number,
                                total: +treeInOne?.threeInOne.price,
                              },
                            }
                          )
                          .then((e) => {
                            if (e.data.fail) {
                              setPending(false);
                              setErrorMessage(
                                lang[currentLanguage].error_message
                              );
                            } else {
                              setPending(false);
                              setSubmitFormTag(e.data?.form);
                            }
                          });
                      } else {
                        setPending(false);
                        setAgreementInfo({
                          text: e.data.cred_info.credit_info_text,
                          icon: e.data.cred_info.icon_en,
                          order_id: e.data.order.id,
                          order_number: e.data.order.order_number,
                        });
                      }
                    } else {
                      setPending(false);
                      setErrorMessage(lang[currentLanguage].error_message);
                      // error
                    }
                  })
                );
              }
            }}
          >
            <span>{data.lang.buy[currentLanguage]}</span>
          </div>
        </div>
        {submitFormTag && (
          <div
            style={{ display: "none" }}
            id="formSubmit"
            dangerouslySetInnerHTML={{ __html: submitFormTag }}
          ></div>
        )}
      </div>
    </div>
  );
}
