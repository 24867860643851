import React, { useEffect, useState } from "react";
import styles from "../style.module.css";
import CloseEyeSvg from "../../../assets/SVG/CloseEyeSvg";
import EyeSvg from "../../../assets/SVG/EyeSvg";
import { useDispatch, useSelector } from "react-redux";
import { UserSignUp, clearError } from "../../../store/MainSlice";
import langData from "../../../lang/data";
import lang from "../../../lang/lang";
import isValidEmail from "helpers/checkEmail";
import isValidPhone from "helpers/checkPhone";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);

  const [success, setSuccess] = useState(false);

  const currentLanguage = useSelector(({ main }) => main.currentLanguage);
  const { errorSignUp } = useSelector(({ main }) => main);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setEmailError(!email || !isValidEmail(email));
    setPhoneError(!phone || !isValidPhone(phone));
    setPasswordError(!password);
    setPassword2Error(!password2 || password !== password2);
    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      password &&
      password2 &&
      password === password2
    ) {
      dispatch(
        UserSignUp(
          { firstName, lastName, email, phone, password, password2 },
          () => setSuccess(true)
        )
      );
    }
  };
  useEffect(() => {
    return () => {
      dispatch(clearError());
    };
  }, []);
  if (success) {
    return (
      <>
        <div className={styles.email_icons}>
          <img src="https://vlv.am/public/img/email.png" alt="img" />
          <div className={styles.top_triangle}>
            <svg
              width="57"
              height="58"
              viewBox="0 0 57 58"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36466 46.5481C0.393336 45.0417 -1.70802 37.6361 2.58222 33.2179L31.7754 3.15454C36.0657 -1.26358 43.5298 0.619414 45.2109 6.54393L56.65 46.8577C58.3311 52.7822 52.9683 58.3049 46.9969 56.7985L6.36466 46.5481Z"
                fill="url(#paint0_radial_1658_50985)"
              ></path>
              <defs>
                <radialGradient
                  id="paint0_radial_1658_50985"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(9.33961 51.7022) rotate(-64.9479) scale(12.2852 22.3958)"
                >
                  <stop stop-color="#404040"></stop>
                  <stop offset="1" stop-color="#212121"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
          <div className={styles.bottom_triangle}>
            <svg
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_f_1658_50986)">
                <path
                  d="M38.5953 11.9138C44.4663 13.7733 46.1226 21.291 41.5768 25.4456L23.4266 42.0337C18.8808 46.1883 11.5421 43.8639 10.217 37.8498L4.92636 13.8373C3.60128 7.8231 9.28361 2.6298 15.1546 4.48932L38.5953 11.9138Z"
                  fill="url(#paint0_radial_1658_50986)"
                ></path>
              </g>
              <defs>
                <filter
                  id="filter0_f_1658_50986"
                  x="0.732178"
                  y="0.105957"
                  width="47.4512"
                  height="48.0288"
                  filterUnits="userSpaceOnUse"
                  colorInterpolationFilters="sRGB"
                >
                  <feFlood
                    floodOpacity="0"
                    result="BackgroundImageFix"
                  ></feFlood>
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  ></feBlend>
                  <feGaussianBlur
                    stdDeviation="2"
                    result="effect1_foregroundBlur_1658_50986"
                  ></feGaussianBlur>
                </filter>
                <radialGradient
                  id="paint0_radial_1658_50986"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(39.8808 8.95611) rotate(118.468) scale(9.22937 16.8251)"
                >
                  <stop stop-color="#404040"></stop>
                  <stop offset="1" stop-color="#212121"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        <div className={styles.we_send}>
          {currentLanguage == "ru"
            ? "Вы успешно зарегистрировались на сайте"
            : currentLanguage == "en"
            ? "You have successfully registered on the site"
            : "Դուք հաջողությամբ գրանցվել եք:"}
        </div>
      </>
    );
  }

  return (
    <>
      <span className={styles.subTitle}>
        {langData.loginModal.FillInYourPersonalInformation[currentLanguage]}
      </span>
      <div className={styles.twoInps}>
        <input
          type="text"
          placeholder={langData.loginModal.FirstName[currentLanguage]}
          className={`${styles.input} ${firstNameError && styles.inputError}`}
          value={firstName}
          onChange={(e) => setFirstName(e.target?.value)}
        />
        <input
          type="text"
          placeholder={langData.loginModal.LastName[currentLanguage]}
          className={`${styles.input} ${lastNameError && styles.inputError}`}
          value={lastName}
          onChange={(e) => setLastName(e.target?.value)}
        />
      </div>
      <div>
        <input
          type="email"
          placeholder={langData.loginModal.email[currentLanguage]}
          className={`${styles.input} ${
            (emailError || errorSignUp?.email?.[0]) && styles.inputError
          }`}
          value={email}
          onChange={(e) => setEmail(e.target?.value)}
        />
        <span className={errorSignUp?.email?.[0] ? styles.errorEmail : ""}>
          {errorSignUp?.email?.[0] &&
            langData?.loginModal?.error?.[currentLanguage]}
        </span>
      </div>
      <input
        type="text"
        placeholder={langData.loginModal.ModaPhone[currentLanguage] + "*"}
        className={`${styles.input} ${phoneError && styles.inputError}`}
        value={phone?.[0] ? (phone?.[0] === "0" ? "" : "+") + phone?.replace(/\D/g, "") : phone} 
        onChange={(e) => setPhone(e.target?.value)}
      />

      <div className={styles.passwordContainer}>
        <input
          type={showPass ? "text" : "password"}
          placeholder={langData.loginModal.password[currentLanguage]}
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={password}
          onChange={(e) => setPassword(e.target?.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass(!showPass)}>
          {!showPass ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <div className={styles.passwordContainer}>
        <input
          type={showPass2 ? "text" : "password"}
          placeholder={langData.loginModal.RepeatPassword[currentLanguage]}
          className={`${styles.input} ${password2Error && styles.inputError}`}
          value={password2}
          onChange={(e) => setPassword2(e.target?.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass2(!showPass2)}>
          {!showPass2 ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <div
        className={`${styles.btn} ${styles.btn_hover}`}
        onClick={handleSubmit}
      >
        <span>{langData.loginModal.signup[currentLanguage]}</span>
      </div>
      <div className={styles.line} />
    </>
  );
}
