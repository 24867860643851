import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import { useDispatch, useSelector } from "react-redux";
import MenuArrowSvg from "./assets/MenuArrowSvg";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { getMenuData } from "../../../../store/MainSlice";
import "swiper/css";
import BrandsSwiper from "./components/BrandsSwiper";
import { Link, useNavigate } from "react-router-dom";
import { getSearchRedirectInfo } from "store/SearchPageSlice";
import langData from "lang/data";

export default function Catalog({ openCatalog, setOpenCatalog }) {
  const [activeMenu, setActiveMenu] = useState(0);
  const [isDinamic, setIsDinamic] = useState(false);
  const { menuData, currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!menuData.length) {
      dispatch(getMenuData());
    } else {
      setIsDinamic(menuData?.[0]?.from !== "category");
    }
  }, [dispatch, menuData]);

  useEffect(() => {
    if (openCatalog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openCatalog]);

  if (!menuData.length) return null;
  return (
    <div className={`${styles.container} ${openCatalog && styles.openMenu}`}>
      <div className={styles.catalogList}>
        <div className={styles.menuItem}
          onClick={() => {
            setOpenCatalog(false);
            navigate(`${currentLanguage !== "hy" ? "/" + currentLanguage : ""}/group-purchase-of-products`);
          }}>
          <div>
            <img
              style={{ width: "auto", height: "auto" }}
              src="https://vlv.am/sale-icon.png"
              alt={"vlv"}
            />
            <Link
              to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/group-purchase-of-products`}

              onClick={() => setOpenCatalog(false)}>

              <span>{langData.header.menu.three_one_price[currentLanguage]}</span>
            </Link>
          </div>
        </div>
        {menuData?.map((elm, index) => {
          if (elm.from === "category") {
            return (
              <div
                className={`${styles.menuItem} ${activeMenu === index ? styles.menuItemActive : ""
                  }`}
                onClick={() => {
                  setActiveMenu(index);
                  setIsDinamic(false);
                }}
                key={index}>
                <div>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + elm.item.icon}
                    alt={elm.item?.name_en}
                  />
                  <Link
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                    to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/${elm?.item?.slug}`}
                    state={"level1"}>
                    {elm.item["name_" + currentLanguage]}
                  </Link>
                </div>
                <MenuArrowSvg />
              </div>
            );
          } else {
            return (
              <div
                className={`${styles.menuItem} ${styles.menuItemDynamic} ${activeMenu === index ? styles.menuItemActive : ""
                  }`}
                onClick={() => {
                  setActiveMenu(index);
                  setIsDinamic(true);
                }}
                key={index}>
                <div>
                  <Link
                    to={`${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                      }/category/filter/page/${elm?.item?.slug}?id=true`}
                    onClick={() => {
                      setOpenCatalog(false);
                    }}
                  >
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        elm.item.header_category_logo
                      }
                      className={styles.menuItemDynamicImg}
                      alt="VLV Banner"
                    />
                  </Link>
                </div>
                <MenuArrowSvg />
              </div>
            );
          }
        })}
      </div>
      <div className={styles.catalogContent}>
        <div className={styles.banner}>
          <Swiper
            modules={[Autoplay]}
            loop
            autoplay
            pagination={{ clickable: true }}
            grabCursor={true}
            centeredSlides={true}>
            {isDinamic
              ? menuData[activeMenu]?.item?.sliders?.map((item, index) => {
                if (item.lang !== currentLanguage) return null;

                return (
                  <SwiperSlide key={index}>
                    <a href={item?.href}>
                      <picture>
                        <img
                          src={
                            process.env.REACT_APP_STORAGE_URL + item?.image
                          }
                          alt={item.title_en}
                          className={styles.bannerImage}
                        />
                      </picture>
                    </a>
                  </SwiperSlide>
                );
              })
              : menuData?.[activeMenu]?.item?.category_slider_image?.map(
                (item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <a href={item?.href}>
                        <picture>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL +
                              item["image_" + currentLanguage]
                            }
                            alt={item.title_en}
                            className={styles.bannerImage}
                          />
                        </picture>
                      </a>
                    </SwiperSlide>
                  );
                }
              )}
          </Swiper>
        </div>
        <div className={styles.content}>
          <Link
            to={
              isDinamic
                ? `${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/category/filter/page/${menuData[activeMenu]?.item?.slug}?id=true`
                : `${currentLanguage !== "hy" ? "/" + currentLanguage : ""
                }/category/${menuData[activeMenu]?.item?.slug}`
            }
            onClick={() => {
              if (isDinamic) {
                dispatch(
                  getSearchRedirectInfo(
                    menuData[activeMenu].item.slug,
                    navigate,
                    currentLanguage,
                    {},
                    true
                  )
                );
              }
              setOpenCatalog(false);
            }}
            className={styles.titleContainer}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                (isDinamic
                  ? menuData[activeMenu]?.item?.header_category_logo
                  : menuData?.[activeMenu]?.item.icon)
              }
              alt={
                isDinamic
                  ? menuData[activeMenu]?.item?.title_en
                  : menuData?.[activeMenu]?.item?.name_en
              }
            />
            <h4 className={styles.title}>
              {isDinamic
                ? menuData[activeMenu]?.item?.["title_" + currentLanguage]
                : menuData?.[activeMenu]?.item["name_" + currentLanguage]}
            </h4>
            {!isDinamic && (
              <span className={styles.count}>
                {menuData?.[activeMenu]?.product_count}
              </span>
            )}
          </Link>
          <div className={styles.subCategories}>
            {(isDinamic
              ? Object.values(menuData[activeMenu].dynamic_category)
              : menuData[activeMenu]?.item?.sub_categories
            )?.map(
              (item, index) =>
                item?.status && (
                  <div
                    className={styles.subCategory}
                    style={
                      !isDinamic
                        ? {
                          gridRowStart: item.grid_row_start,
                          gridRowEnd: item.grid_row_end,
                          gridColumnStart: item.grid_column_start,
                          gridColumnEnd: item.grid_column_end,
                        }
                        : null
                    }
                    key={index}>
                    <Link
                      to={
                        !isDinamic
                          ? `${currentLanguage !== "hy"
                            ? "/" + currentLanguage
                            : ""
                          }/category/filter/${item.slug}`
                          : `${currentLanguage !== "hy"
                            ? "/" + currentLanguage
                            : ""
                          }/category/filter/${item.slug}${menuData[activeMenu]?.item?.brand_ids
                            ? `?b=${menuData[activeMenu]?.item?.brand_ids}`
                            : ""
                          }`
                      }
                      onClick={() => {
                        setOpenCatalog(false);
                      }}
                      className={styles.subCategoryTitle}>
                      <img
                        src={process.env.REACT_APP_STORAGE_URL + item.icon}
                        alt={item?.name_en}
                      />
                      <h6>{item["name_" + currentLanguage]}</h6>
                    </Link>
                    {item?.sub_categories?.map((subItem, index) => {
                      if (subItem?.status) {
                        return (
                          <div
                            className={styles.subCategorySubs}
                            key={index}>
                            <Link
                              to={`${currentLanguage == "hy"
                                ? ""
                                : "/" + currentLanguage
                                }/category/filter/${subItem.slug}`}
                              onClick={() => {
                                setOpenCatalog(false);
                              }}
                              className={styles.subCategoryTitle}>
                              <img
                                src={
                                  process.env.REACT_APP_STORAGE_URL +
                                  subItem.icon
                                }
                                alt={subItem?.name_en}
                              />
                              <span className={styles.subCategorySpan}>
                                {subItem["name_" + currentLanguage]}
                              </span>
                            </Link>
                          </div>
                        );
                      }
                    })}
                  </div>
                )
            )}
            {!isDinamic && !!menuData[activeMenu]?.apple?.length && (
              <div
                className={styles.subCategory}
                style={{
                  gridRowStart: 1,
                  gridRowEnd: 5,
                  gridColumnStart: 3,
                  gridColumnEnd: 3,
                }}>
                <Link
                  to={`${currentLanguage == "hy" ? "" : "/" + currentLanguage
                    }/brand/apple/category`}
                  onClick={() => {
                    setOpenCatalog(false);
                  }}
                  className={styles.subCategoryTitle}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#d10000"
                    height="20px"
                    width="20px"
                    version="1.1"
                    id="Capa_1"
                    viewBox="0 0 22.773 22.773">
                    <g>
                      <path d="M15.769,0c0.053,0,0.106,0,0.162,0c0.13,1.606-0.483,2.806-1.228,3.675c-0.731,0.863-1.732,1.7-3.351,1.573    c-0.108-1.583,0.506-2.694,1.25-3.561C13.292,0.879,14.557,0.16,15.769,0z" />
                      <path d="M20.67,16.716c0,0.016,0,0.03,0,0.045c-0.455,1.378-1.104,2.559-1.896,3.655c-0.723,0.995-1.609,2.334-3.191,2.334    c-1.367,0-2.275-0.879-3.676-0.903c-1.482-0.024-2.297,0.735-3.652,0.926c-0.155,0-0.31,0-0.462,0    c-0.995-0.144-1.798-0.932-2.383-1.642c-1.725-2.098-3.058-4.808-3.306-8.276c0-0.34,0-0.679,0-1.019    c0.105-2.482,1.311-4.5,2.914-5.478c0.846-0.52,2.009-0.963,3.304-0.765c0.555,0.086,1.122,0.276,1.619,0.464    c0.471,0.181,1.06,0.502,1.618,0.485c0.378-0.011,0.754-0.208,1.135-0.347c1.116-0.403,2.21-0.865,3.652-0.648    c1.733,0.262,2.963,1.032,3.723,2.22c-1.466,0.933-2.625,2.339-2.427,4.74C17.818,14.688,19.086,15.964,20.67,16.716z" />
                    </g>
                  </svg>
                  <h6>Apple</h6>
                </Link>
                {menuData[activeMenu]?.apple?.map((subItem, index) => {
                  if (subItem?.status) {
                    return (
                      <div
                        className={styles.subCategorySubs}
                        key={index}>
                        <Link
                          to={`${currentLanguage == "hy" ? "" : "/" + currentLanguage
                            }/category/filter/${subItem.slug}?b=73`}
                          onClick={() => {
                            setOpenCatalog(false);
                          }}
                          className={styles.subCategoryTitle}>
                          <img
                            src={
                              process.env.REACT_APP_STORAGE_URL + subItem.icon
                            }
                            alt={subItem?.name_en}
                          />
                          <span className={styles.subCategorySpan}>
                            {subItem["name_" + currentLanguage]}
                          </span>
                        </Link>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      <BrandsSwiper data={menuData?.[activeMenu]} />

      <CloseSvg
        className={styles.closeBtn}
        onClick={() => setOpenCatalog(false)}
      />
    </div>
  );
}
