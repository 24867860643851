import React from "react";
import style from "./style.module.css";
import UseCalcPrice from "../../../../helpers/UseCalcPrice";
import { useSelector } from "react-redux";
import UseGetDate from "../../../../helpers/UseGetDate";

export default function ThanksModal({
  data,
  product,
  close,
  qty,
  totalPrice,
  show_payment_type = true, 
}) { 
  const { currentCurrency, currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className={style.product_modal_block}>
      <div className={style.product_modal_bg}></div>
      <div className={style.modal_content_scrool}>
        <div className={style.modal_content_img_title}>
          <img
            data-src="https://vlv.am/public/img/shnor.png"
            src="https://vlv.am/public/img/shnor.png"
            alt={""}
          />
          <div className={style.modal_content_title}>
            <h3 className="second_title">Շնորհակալություն</h3>
            <span className="toxir">Ձեր պատվերը ընդունված է:</span>
          </div>
        </div>

        <div className={style.finish_check_block_border}>
          <div className={style.finish_check_block}>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>
                {UseGetDate(null, currentLanguage)}
              </span>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>Պատվեր №</span>
              <p>{data?.order_number}</p>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>Պատվիրող</span>
              <p>{data.name + " " + data.surname}</p>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>Հեռախոս</span>
              <p>{data.phone}</p>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>Էլ․ փոստի հասցե</span>
              <p>{data.email}</p>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>Հասցե</span>
              <p>{data.address_inp}</p>
            </div>
            <div className={style.check_info_element}>
              <span className={style.bold_first_text}>
                Լրացրեք բնակարան/ տուն / հարկ
              </span>
              <p>{data.flat}</p>
            </div>
            {show_payment_type && (
              <div className={style.check_info_element}>
                <span className={style.bold_first_text}>Վճարման տարբերակ</span>
                <p>Օնլայն ապառիկ</p>
              </div>
            )}
          </div>
          <div className={style.total_info_col}>
            <div className={style.prod_count_imgs}>
              <div className={style.count_title_check}>
                <span className={style.bold_first_text}>Ապրանքի քանակ</span>
                <p>{qty}</p>
              </div>
              <div className={style.prod_imgs_plus}>
                {Array.isArray(product)
                  ? product.map((product, index) => (
                      <img
                        className={style.round_mini_img}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          (product?.image ||
                            product.product?.gallary_images_api?.[0]
                              ?.thum_image_sourc)
                        }
                        style={{
                          position: "relative",
                          right: index * 10 + "px",
                        }}
                        alt={""}
                      />
                    ))
                  : product?.gallary_images_api?.[0]?.thum_image_sourc && (
                      <img
                        className={style.round_mini_img}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          product?.gallary_images_api?.[0]?.thum_image_sourc
                        }
                        alt={""}
                      />
                    )}
              </div>
            </div>
            <div className={style.prod_count_imgs}>
              <span className={style.prod_total}>Ընդհանուր</span>
              <span
                className={style.prod_total_price}
                id="order_total">
                {totalPrice
                  ? UseCalcPrice(totalPrice, currentCurrency)
                  : UseCalcPrice(
                      product?.skus?.[0].promo_price ||
                        product?.skus?.[0].selling_price,
                      currentCurrency
                    )}
              </span>
            </div>
          </div>
          <div
            onClick={() => close()}
            className={style.backto_btn}>
            Վերադառնալ գնումներին
          </div>
        </div>
        <div
          onClick={() => close()}
          className={style.close_shnor}>
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 1.15674L16 16.1567M16 1.15674L1 16.1567"
              stroke="#282828"></path>
          </svg>
        </div>
      </div>
    </div>
  );
}
